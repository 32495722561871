<template>
	<div class="login-screen">
		<img :src="bgImage" class="background-login" />
		<div class="content-login bigscreen">
			<div class="koltipay-logo">
				<a href="https://koltiva.com/" target="_blank" align="left">
					<img :src="ktvLogoImage" alt="Koltiva Beyond Traceability" />
				</a>
			</div>
			<div class="middle-part">
				<div class="tagline-desc">
					Responsible digital finance
					<br />
					and payments
				</div>
				<div class="card-login">
					<div class="div-logolite">
						<img :src="logoLite" />
					</div>
					<b-form @submit.prevent="formSubmit">
						<b-form-group label="Username or Email" class="label-form">
							<b-form-input
								v-model="userId"
								autocomplete="username"
								autofocus
								class="input-form"
								placeholder="Email / Username"
								required
								type="text"
							/>
							<span class="Icon__Form text-25">
								<img :src="iconemail" />
							</span>
						</b-form-group>
						<b-form-group label="Password" class="label-form">
							<b-form-input
								v-model="password"
								:type="typepass"
								autocomplete="current-password"
								autofocus
								class="input-form"
								placeholder="Password"
								required
							/>
							<span class="Icon__Form text-25">
								<img :src="iconlock" />
							</span>
							<span class="eyeright text-25">
								<img :src="iconeye" @click="actionpass" />
							</span>
						</b-form-group>
						<b-button type="submit" tag="button" class="btn-block button-form" variant="primary" :disabled="loading">
							Login&nbsp;
							<img v-if="loading" :src="loaderGif" alt="" />
						</b-button>
						<p class="text-agreement text-left">
							By signing in, you agree with our
							<a href="https://koltiva.com/privacy-policy" target="_blank" class="terms-link">Terms</a> and
							<a href="https://koltiva.com/privacy-policy" target="_blank" class="terms-link">Privacy Policy</a>
						</p>
					</b-form>
				</div>
			</div>
			<div class="img-footer">
				<a href="https://koltiva.com/" target="_blank" align="left">
					<img :src="taglineImage" alt="Koltiva Beyond Traceability" />
				</a>
			</div>
		</div>
		<div class="footer">
			<p class="text-white text-center">&copy; 2017 - 2022 | Koltiva AG and its affiliates. All rights reserved.</p>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapMutations } from "vuex"

	import BgImage from "@/assets/images/koltipay-bg.png"
	import EmailIcon from "@/assets/images/mail.png"
	import EyeIconOff from "@/assets/images/eye-off.png"
	import EyeIconOn from "@/assets/images/eye-on.png"
	import KtvLogoImage from "@/assets/images/koltipay_logos_rgb.svg"
	import LoaderImage from "@/assets/images/ajax-loader.gif"
	import LockIcon from "@/assets/images/lock.png"
	import LogoLiteImage from "@/assets/images/koltipay-logo-lite.svg"
	import TagLineImage from "@/assets/images/koltiva_tagline_logo.svg"

	export default {
		metaInfo: {
			title: "Koltipay Web Portal",
		},
		name: "KoltipayWebPortal",
		data() {
			return {
				bgImage: BgImage,
				iconemail: EmailIcon,
				iconeye: EyeIconOff,
				iconlock: LockIcon,
				ktvLogoImage: KtvLogoImage,
				loaderGif: LoaderImage,
				logoLite: LogoLiteImage,
				password: "",
				taglineImage: TagLineImage,
				typepass: "password",
				userId: "",
			}
		},
		computed: {
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
				isUserLoggedIn: "AUTHDATA/isUserLoggedIn",
				loading: "AUTHDATA/loading",
				error: "AUTHDATA/error",
			}),
			validation() {
				return this.userId.length > 4 && this.userId.length < 100
			},
		},
		watch: {
			error(val) {
				if (val != null) {
					this.makeToast("Error", "danger", val.error_message)
				}
			},
			loggedInUser(val) {
				if (val && val.UserId && val.UserId !== "") {
					this.makeToast("success", "Successfully Logged In")
					const self = this

					setTimeout(() => {
						const firstMenu = this.loggedInUser?.menu[0]

						if (firstMenu && firstMenu.MenuModule && (firstMenu.MenuModule === "/premium-point" || firstMenu.MenuModule === "/loan")) {
							if (firstMenu.Child && firstMenu.Child.length > 0) {
								const firstChild = firstMenu.Child[0]

								if (firstChild.MenuModule) {
									self.$router.push(firstChild.MenuModule)
								} else {
									self.$router.push({
										name: "dashboard.v1",
									})
								}
							} else {
								self.$router.push(firstMenu.MenuModule)
							}
						} else {
							self.$router.push({
								name: "dashboard.v1",
							})
						}
					}, 500)
				}
			},
		},
		created() {
			this.setLoading(false)
		},
		methods: {
			...mapActions({
				loginJWT: "AUTHDATA/loginJWT",
			}),
			...mapMutations({
				setLoading: "AUTHDATA/setLoading",
			}),
			actionpass() {
				if (this.typepass === "text") {
					this.typepass = "password"
					this.iconeye = EyeIconOff
				} else {
					this.iconeye = EyeIconOn
					this.typepass = "text"
				}
			},
			formSubmit() {
				if (!this.validation) {
					this.makeToast("Warning", "warning", "Please check value, username must be greater than 4 characters")
				} else {
					let bodyFormData = new FormData()
					bodyFormData.append("username", this.userId)
					bodyFormData.append("password", this.password)
					this.loginJWT(bodyFormData)
				}
			},
			makeToast(title, variant, msg) {
				this.$bvToast.toast(msg, {
					title: ` ${title || "default"}`,
					variant: variant,
					solid: true,
				})
			},
		},
	}
</script>

<style scoped>
	.img-footer {
		position: absolute;
		padding: 0 0 5rem 0;
		bottom: 0;
	}
	.terms-link {
		color: #22232a;
		font-weight: 510;
		text-decoration: underline;
	}
	.terms-link:hover {
		color: #22232a;
		font-weight: 510;
		text-decoration: underline;
	}
	.text-agreement {
		color: #676768;
		font-weight: 400;
		font-size: 1rem;
		line-height: 24px;
	}
	.button-form {
		background-color: #54a7dc;
		border: #54a7dc;
		border-radius: 10px;
		margin: 16px 0px;
		padding: 16px 48px;
		font-weight: 700;
		font-size: 16px;
		line-height: 1.5rem;
	}
	.button-form:hover {
		background: #4b96c6;
		box-shadow: none;
	}
	.eyeright {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background: none;
		border: none;
		bottom: 0;
		color: #718096;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-top: 10px;
		margin-right: 1rem;
		outline: none;
		position: absolute;
		right: 0;
		top: 0;
	}
	.Icon__Form {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background: none;
		border: none;
		bottom: 0;
		color: #718096;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-top: 10px;
		margin-left: 1rem;
		outline: none;
		position: absolute;
		left: 0;
		top: 0;
	}
	.input-form {
		margin-top: 10px;
		background: #ffffff;
		border: 1px solid #dbdbdb;
		border-radius: 10px;
		padding: 16.5px 14px 16.5px 49px;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		height: auto;
	}
	.label-form .col-form-label {
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		text-align: left;
		padding: 0 !important;
	}
	.div-logolite {
		margin-bottom: 26px;
		text-align: center;
	}
	.card-login {
		background: #ffffff;
		border-radius: 15px;
		max-width: 400px;
		width: 100%;
		padding: 2rem 20px;
		position: absolute;
		top: 50%;
		left: 84.4%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.tagline-desc {
		font-size: 32px;
		letter-spacing: 0.05em;
		font-weight: 400;
		line-height: 40px;
		color: #22232a;
	}
	.middle-part {
		display: flex;
		justify-content: space-between;
	}
	.koltipay-logo {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		display: grid;
		margin: 0 0 80px -10px;
	}
	.content-login {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		padding: 5rem 0 5rem 0;
		height: 100%;
		width: 100%;
	}

	@media (min-width: 640px) {
		.content-login {
			max-width: 640px;
		}
	}
	@media (min-width: 768px) {
		.content-login {
			max-width: 768px;
		}
	}
	@media (min-width: 1024px) {
		.tag-desc {
			margin-top: 100px;
			font-size: 30px;
		}
		.content-login {
			max-width: 1024px;
		}
	}
	@media (min-width: 1280px) {
		.content-login {
			max-width: 1280px;
		}
	}

	.login-screen {
		font-family: "Roboto", Helvetica, Arial, sans-serif;
		height: 94vh;
		position: relative;
	}
	.background-login {
		position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	.footer {
		background: #22232a;
		padding: 0;
		margin: 0;
		letter-spacing: normal;
		line-height: 6vh;
		font-size: 14px;
	}
</style>
